<template>
  <div id="page">
    <section class="product-list-heading box-my-account mb-0">
      <div class="container-fluid">
        <div class="row">
          <ProfileNavbar
            :parentData="userData.full_name"
          ></ProfileNavbar>

          <div id="panel" class="col-md-9 py-5 px-lg-5">
            <h2>My Reviews</h2>
            <ProductReviewProfileNavTab class="mt-3" />
            <div class="tab-content">
              <ListUnreviewedProduct @scrollToTop="scrollToTop" />
              <ListReviewedProduct @scrollToTop="scrollToTop" />
            </div>
          </div>
        </div>
      </div>
    </section>
    
  </div>
</template>

<script>
import ProfileNavbar from "@/components/mains/ProfileNavbar.vue";
const ProductReviewProfileNavTab = () => import("@/components/profile/review/ProductReviewProfileNavTab.vue");
const ListReviewedProduct = () => import("@/components/profile/review/ListReviewedProduct.vue");
const ListUnreviewedProduct = () => import("@/components/profile/review/ListUnreviewedProduct.vue");

export default {
  name: "ProductReviewProfile",
  data() {
    return {
    };
  },
  components: {
    ProfileNavbar,
    ProductReviewProfileNavTab,
    ListReviewedProduct,
    ListUnreviewedProduct,
  },
  computed: {
    userData() {
      return this.$store.getters.getUserData;
    },
  },
  mounted() {
  },
  methods: {
    scrollToTop() {
      // console.log("scrollToTop triggered in parent")
      // const yOffset = -70;
      // const element = document.getElementById("panel");
      // const y =
      //   element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      // window.scrollTo({ top: y, behavior: "smooth" });
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  },
};
</script>

<style scoped>
/* @media only screen and (max-width: 450px) {
  .center-img {
    float: none;
  }
} */
</style>